<template>
    <Row>
      <Column :width="15" :key="redraw">
        <div id="detailsModal" class="rc-modal-dialog">
          <!-- Modal content -->
          <div class="rc-modal-content-dialog-inner-wide-tall">
            <div class="rc-modal-header-dialog">
              <Box :key="redrawHeader">
                <Row 
                  v-for="(row, rowIndex) in viewHeaderData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
            <div class="rc-modal-body-dialog">
              <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box :key="redrawMain">
                  <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
              </div>
            </div>
  
            <div class="rc-modal-footer-dialog">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewFooterData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>
        </div>
      </Column>
    </Row>
</template>

<script> 

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow.js";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import PdfDisplayRow from '@/portals/shared/cell/pdf/PdfDisplayRow';

import Inspection from "@/domain/model/inspection/Inspection.js";

export default {
  name: "inspection-list-pdf-view",
  components: {
    CellFactory,
    Box, Column, Row,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
        redraw: 1,
        redrawMain: 10000,
        redrawHeader: 20000,

        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,

        showMissingRequirements: false,
        viewHeaderData: [],
        viewBodyData: [],
        viewFooterData: [],

        inspection: null,
        company: null,
        pdfUrls: [],

        index: 0,

        kvps: {},
        error: null,
        showDialog: null,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },

  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },

  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },

  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany',
                   ]),
    
    start: function() {
      this.inspection = new Inspection(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.inspection = this.domain.inspections().findById(id).copy();
        this.company = this.inspection.company().find();
        this.pdfUrls = this.inspection.uniquePdfUrls();
      }
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      // window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let fullScreenButton = new ButtonDisplayRow().withWidth("2").withField("FullScreen").withName("Full Screen");
      let closeButton = new ButtonDisplayRow().withWidth("2").withField("CloseDialog").withName("Close");
      let previousButton = new ButtonDisplayRow().withWidth("2").withField("Previous").withName("Previous");
      let nextButton = new ButtonDisplayRow().withWidth("2").withField("Next").withName("Next");

      let footerRow = new RowDisplayRow()
        .withChild(closeButton)
        .withChild(new SpacerDisplayRow().withWidth("9"))
        .withChild(previousButton)
        .withChild(nextButton);
      
      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Inspection PDF Display"));
      this.viewFooterData.push(footerRow);

      let headerRow = new RowDisplayRow().withWidth("15");
      let pdfIndex = this.index + 1;
      let pdfIndexDisplay = new ValueDisplayRow()
        .withValue("PDF " + pdfIndex + " of " + this.pdfUrls.length)
        .withWidth("13");
      headerRow.withChild(pdfIndexDisplay);
      headerRow.withChild(fullScreenButton);

      this.viewHeaderData.push(headerRow);

      let pdf = this.pdfUrls[this.index];
      let pdfRow = new PdfDisplayRow()
        .withPdfUrl(pdf)
        .withWidth("15");

      let pdfBox = new BoxDisplayRow().withWidth("15");
      pdfBox.withChild(pdfRow);

      this.viewBodyData.push(pdfBox);
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },

    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        this.domain.logger().info("Received: {0}", [JSON.stringify(valueData)]);
        this.resetMissingValues();

        if (!valueData) {
          return;
        }

        this.$emit("withFieldValue", valueData);
      }
    },

    withButtonPressed: function(buttonData) {
      if (buttonData["field"] == "Previous") {
        if (this.index > 0) {
          this.index--;
          this.mainScreen();
          this.paintHeader();
          this.paintMain();
        }
      }
      if (buttonData["field"] == "Next") {
        if (this.index < this.pdfUrls.length - 1) {
          this.index++;
          this.mainScreen();
          this.paintHeader();
          this.paintMain();
        }
      }

      if (buttonData["field"] == "FullScreen") {
        this.printInspection();
      }

      if (buttonData["field"] == "CloseDialog") {
        this.$emit("withButtonPressed", buttonData);
      }
      
    },

    printInspection() {
      let list = this.pdfUrls;
      for (let i = 0; i < list.length; i++) {
        let path = list[i];
        if (i == 0) {
          this.openUrlInBlankWindow(path, "schedule");
        } else {
          setTimeout(this.openUrlInBlankWindow, 1250, path, "checklists");
        }
      }
    },

    openUrlInBlankWindow(path, target = '_blank') {
      var url = ContentUtils.escape(path);
      url = url.replace("#", "%23");
      window.open(url, target);
    },

    paintMain: function() {
      this.redrawMain++;
    },

    paintHeader: function() {
      this.redrawHeader++;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet() {
      var met = true;
      return met;
    },
  },
}

</script>