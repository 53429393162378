<template>
  <Row>
    <Column :width="15">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-wide-tall">
          <div class="rc-modal-header-dialog">
            <Box :key="redraw">
              <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'"></WorkingDisplayRow>
              <UpdateRow v-if="this.showDialog == 'updateDialog'" :vehicleId="itemId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateRow>
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box v-if="this.isDetails" :key="redraw + 1">
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
              <Box v-else>
                <InspectionListView 
                  :vehicleId="itemId"
                  v-on:withValue="withFieldValue"
                  v-on:withButtonPressed="withButtonPressed">
                </InspectionListView>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box :key="redraw + 2">
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import PictureDisplayRow from "@/portals/shared/cell/picture/PictureDisplayRow";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import InspectionListView from '@/portals/customer/operator/views/inspection/list/ListView.vue';

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import UpdateRow from '../update/UpdateRow.vue';

export default {
    name: "vehicle-list-details-view",
    components: {
        Column,
        Row,
        Box,
        CellFactory,
        WorkingDisplayRow,
        InspectionListView,
        UpdateRow,
    },
    props: {
        itemId: { type: String, default: "" },
    },
    data() {
        return {
            redraw: 1,
      
            MC: new MC(),
            Strings: StringUtils,
            Contents: ContentUtils,
            Consts: ConstUtils,

            vehicle: null,
            company: null,

            isDetails: true,

            showMissingRequirements: false,
            viewBodyData: [],
            viewFooterData: [],
            viewHeaderData: [],

            kvps: {},
            error: null,
            showDialog: null,
        }
    },
    computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   'VehicleStorage_lastEvent',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    VehicleStorage_lastEvent() {
      let vehicle = this.domain.vehicles().findById(ContentUtils.baseDecode(this.itemId));
      if (this.vehicle.modifiedDate() < vehicle.modifiedDate()) {
        this.vehicle = vehicle;
        this.company = this.vehicle.company().find();
        this.showDialog = null;
        this.paint();
        this.mainScreen();
      }
    },
    $route (to, from){
      if (to && from) {
        if (to.query["vehicleListAction"] === "detailsDialog") {
          this.isDetails = true;
          this.paint();
          this.mainScreen();
        }
        if (to.query["vehicleListAction"] === "inspectionsDialog") {
          this.isDetails = false;
          this.paint();
          this.mainScreen();
        }
      }

    },
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },

  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.vehicle = new Vehicle(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.vehicle = this.domain.vehicles().findById(id);
        this.company = this.vehicle.company().find();
      }

      this.isDetails = this.actionIsDetails();
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      // window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.paint();

      let titleString = this.isDetails ? "Details" : "Inspections";
      let formatted = this.vehicle.isTrailer() 
        ? StringUtils.format("Trailer {1} - {0}", [this.vehicle.displayName(), titleString]) 
        : StringUtils.format("Vehicle {1} - {0}", [this.vehicle.displayName(), titleString]);

      let vehicleDetailsButton = new ButtonDisplayRow()
        .withWidth(3)
        .withName("Details")
        .withField("VehicleDetailsSelect")
        .withSelected(this.isDetails);

      let vehicleInspectionsButton = new ButtonDisplayRow()
        .withWidth(3)
        .withName("Inspections")
        .withField("VehicleDetailsInspectionSelect")
        .withSelected(!this.isDetails);
      
      let tabRow = new RowDisplayRow().withWidth(15).withChildren([
      new SpacerDisplayRow().withWidth(9),
        vehicleDetailsButton,
        vehicleInspectionsButton,
      ]);
      this.viewHeaderData.push(tabRow);  
      this.viewHeaderData.push(new TitleDisplayRow().withTitle(formatted));

      this.viewFooterData.push(new RowDisplayRow().withWidth(15).withBorder().withChildren([
        new ButtonDisplayRow().withWidth(2).withName("Close").withField("VehicleDetailsDialogClose"),
        new SpacerDisplayRow().withWidth(11),
        new ButtonDisplayRow().withWidth(2).withName("Update").withField("VehicleDetailsUpdateSelect"),
      ]));

      let vehicle = this.vehicle;

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }

      let image = vehicle.images().find().first();
      let mainDetailsRow = new RowDisplayRow().withWidth(15).withChildren([
        new BoxDisplayRow().withWidth(10).withBorder().withChildren([
          new TitleDisplayRow()
                .withWidth(15)
                .withSubtitle(true)
                .withTitle(this.MC.Vehicle.Add.RequiredDetails.value()),

          new RowDisplayRow().withWidth(15).withChildren([
            new LabelValueDisplayRow()
              .withLabelWidth(2)
              .withLabel(this.MC.Vehicle.Add.Name.value())
              .withField(Vehicle.FIELDS.NAME)
              .withWidth(10)
              .withValue(vehicle.name()),

            new LabelValueDisplayRow()
              .withLabelWidth(5)
              .withLabel(this.MC.Vehicle.Add.Year.value())
              .withField(Vehicle.FIELDS.YEAR)
              .withWidth(5)
              .withValue(vehicle.year()),
          ]),

          new RowDisplayRow().withWidth(15).withChildren([
            new LabelValueDisplayRow()
              .withLabelWidth(5)
              .withLabel(this.MC.Vehicle.Add.VehicleType.value())
              .withField(Vehicle.FIELDS._CATEGORY)
              .withWidth(5)
              .withValue(this.categoryName()),

            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.BrakeType.value())
              .withLabelWidth(5)
              .withField(Vehicle.FIELDS.BRAKE_TYPE)
              .withWidth(5)
              .withValue(StringUtils.titleCase(vehicle.brakeType())),

            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.Units.value())
              .withLabelWidth(5)
              .withField(Vehicle.FIELDS.ODOMETER_TYPE)
              .withWidth(5)
              .withValue(vehicle.odometerType()),
          ]),

          new RowDisplayRow().withWidth(15).withChildren([
            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.Jurisdiction.value())
              .withLabelWidth(5)
              .withField(Vehicle.FIELDS.JURISDICTION)
              .withWidth(5)
              .withValue(vehicle.jurisdiction()),

            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.Plate.value())
              .withLabelWidth(5)
              .withField(Vehicle.FIELDS.PLATE)
              .withWidth(5)
              .withValue(vehicle.plate()),

            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.PlateExpiryDateLabel.value())
              .withLabelWidth(5)
              .withField(Vehicle.FIELDS.PLATE_EXPIRY_DATE)
              .withWidth(5)
              .withValue(vehicle.plateExpiryDate()),
          ]),

          new BoxDisplayRow().withWidth(15).withBorder().withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withTitle("Schedule"),

            new RowDisplayRow().withChildren([]),
            new LabelValueDisplayRow()
              .withLabel(this.MC.Vehicle.Add.Schedule.value())
              .withLabelWidth(2)
              .withField(Vehicle.FIELDS.SCHEDULE)
              .withValue(vehicle.schedule().find().name()),
          ]),

          new BoxDisplayRow().withBorder().withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withTitle(this.MC.Vehicle.Add.NotesLabel.value()),

            new StringDisplayRow()
              .withAlignLeft()
              .withValue("Additional information about this vehicle. This will not be displayed to a driver on the mobile app."),

            new LabelValueDisplayRow()
              .withLabel("Notes")
              .withLabelWidth(2)
              .withField(Vehicle.FIELDS.NOTES)
              .withValue(vehicle.notes()),
          ]),
        ]),

        new BoxDisplayRow().withWidth(5).withBorder().withChildren([
          new RowDisplayRow().withWidth(15).withBorder(true).withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withWidth(15)
              .withTitle(this.MC.Vehicle.Add.Picture.value()),

            new PictureDisplayRow()
              .withWidth(15)
              .withDefaultValue(image)
              .withField(Vehicle.FIELDS.IMAGE_DATA),
          ]),

          new RowDisplayRow().withBorder(true).withChildren([
            new TitleDisplayRow()
              .withSubtitle(true)
              .withWidth(15)
              .withTitle("State and Status"),

            new DefectTypeDisplayRow()
              .withValue(vehicle.defectType())
              .withWidth(15)
              .withLabel("Status"),

            new StateDisplayRow()
              .withValue(vehicle.state())
              .withWidth(15)
              .withLabel("State"),
          ]),
        ]),
      ]);

      let startEndRow = new RowDisplayRow().withBorder(true).withChildren([
        new TitleDisplayRow()
          .withSubtitle(true)
          .withWidth("15")
          .withTitle("Checklist"),

        new LabelValueDisplayRow()
          .withLabel(this.MC.Vehicle.Add.Checklist.value())
          .withLabelWidth(5)
          .withField(Vehicle.FIELDS.Checklists)
          .withWidth(15)
          .withValue(vehicle.checklists().find().first().name()),

        new TitleDisplayRow()
          .withSubtitle(true)
          .withWidth("15")
          .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),

        new LabelValueDisplayRow()
          .withLabel(this.MC.Vehicle.Add.StartDateLabel.value())
          .withLabelWidth(5)
          .withField(Vehicle.FIELDS.START_DATE)
          .withWidth(15)
          .withValue(ContentUtils.decodeDate(vehicle.startDate())),

        new LabelValueDisplayRow()
          .withLabel(this.MC.Vehicle.Add.EndDateLabel.value())
          .withLabelWidth(5)
          .withField(Vehicle.FIELDS.END_DATE)
          .withWidth(15)
          .withValue(ContentUtils.decodeDate(vehicle.endDate())),
      ]);

      let odometerRows = [];

      if (vehicle.isTrailer()) {
        if (vehicle.isHubometerUsed()) {
          odometerRows.push(new LabelValueDisplayRow()
            .withLabelWidth(5)
            .withLabel("Hubometer")
            .withField(Vehicle.FIELDS.ODOMETER)
            .withWidth(15)
            .withValue(vehicle.odometer()));
        }
      } else {
        odometerRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Vehicle.Add.Odometer.value())
          .withLabelWidth(5)
          .withField(Vehicle.FIELDS.ODOMETER)
          .withWidth(15)
          .withValue(vehicle.odometer()));
      }
      let middleRows = [];
      middleRows.push(
        new RowDisplayRow().withBorder(true).withWidth(15).withChildren([
          new TitleDisplayRow()
            .withSubtitle(true)
            .withWidth(15)
            .withTitle(this.MC.Vehicle.Add.SafetyTitle.value()),
          new LabelValueDisplayRow()
            .withLabel(this.MC.Vehicle.Add.Pmvi.value())
            .withField(Vehicle.FIELDS.ANNUAL_DATE)
            .withLabelWidth(5)
            .withWidth(15)
            .withValue(vehicle.annualDateV2().displayAs_YYYY_MM_DD()),
          new LabelValueDisplayRow()
            .withLabel(this.MC.Vehicle.Add.PmviId.value())
            .withLabelWidth(5)
            .withField(Vehicle.FIELDS.ANNUAL_NUMBER)
            .withWidth(15)
            .withValue(vehicle.safetyNumber()),
      ]));

      middleRows.push(
        new RowDisplayRow().withBorder(true).withChildren([
          new TitleDisplayRow()
            .withSubtitle(true)
            .withWidth(15)
            .withTitle(this.MC.Vehicle.Add.VinWeightTitle.value()),

          new LabelValueDisplayRow()
            .withLabel(this.MC.Vehicle.Add.Vin.value())
            .withLabelWidth(5)
            .withValue(vehicle.vin())
            .withWidth(15)
            .withField(Vehicle.FIELDS.VIN),

          new LabelValueDisplayRow()
            .withLabel(vehicle.isTrailer() ? this.MC.Vehicle.Add.RegisteredGrossVehicleWeight.value() : this.MC.Vehicle.Add.Weight.value())
            .withValue(vehicle.weight())
            .withLabelWidth(5)
            .withWidth(15)
            .withField(Vehicle.FIELDS.WEIGHT),
      ]));

      let lastRows = [];
      lastRows.push(
        new RowDisplayRow().withBorder(true).withWidth(15).withChildren([
          new TitleDisplayRow()
            .withWidth(15)
            .withSubtitle(true)
            .withTitle(this.MC.Vehicle.Add.OdometerAndMoreTitle.value()),
          ...odometerRows,

          new LabelValueDisplayRow()
            .withLabel(this.MC.Vehicle.Add.Summary.value())
            .withField(Vehicle.FIELDS.SUMMARY)
            .withLabelWidth(5)
            .withWidth(15)
            .withValue(vehicle.summary()),

          new LabelValueDisplayRow()
            .withWidth(15)
            .withLabel(this.MC.Vehicle.Add.Make.value())
            .withValue(vehicle.make())
            .withLabelWidth(5)
            .withField(Vehicle.FIELDS.MAKE),

          new LabelValueDisplayRow()
            .withWidth(15)
            .withLabel(this.MC.Vehicle.Add.Model.value())
            .withLabelWidth(5)
            .withValue(vehicle.model())
            .withField(Vehicle.FIELDS.MODEL),

          new LabelValueDisplayRow()
            .withWidth(15)
            .withLabel(this.MC.Vehicle.Add.Colour.value())
            .withLabelWidth(5)
            .withValue(vehicle.colour())
            .withField(Vehicle.FIELDS.COLOUR),
      ]));

      let kids = [];
      kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChild(startEndRow)));
      kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChildren(middleRows)));
      kids.push(new RowDisplayRow().withWidth(5).withChild(new BoxDisplayRow().withWidth(15).withChildren(lastRows)));
      kids.push(new SpacerDisplayRow());

      this.viewBodyData.push(mainDetailsRow);
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow().withChildren(kids)));

      this.paint();
    },

    categoryName() {
      let category = this.vehicle.category().find();
      let list = ContentUtils.CATEGORIES();
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item['@rid'] == category.id()) {
          return item['name'];
        }
      }
      return category.name();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },

    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    areRequirementsMet() {
      var met = true;
      return met;
    },

    goBack: function() {
      this.$router.back();
    },

    withFieldValue: function(valueData) {
      this.$emit("withValue", valueData);
    },

    withButtonPressed: function(buttonData) {
      if (!buttonData) {
        return;
      }

      if (buttonData.field == "VehicleDetailsUpdateSelect") {
        this.showDialog = "updateDialog";
        this.$emit("withButtonPressed", buttonData);  
      }

      if (buttonData.field == "VehicleDetailsSelect") {
        const query = this.urlQueryParams();
        query["vehicleListAction"] = "detailsDialog";
        this.$router.push({ query });
      }

      if (buttonData.field == "VehicleDetailsInspectionSelect") {
        const query = this.urlQueryParams();
        query["vehicleListAction"] = "inspectionsDialog";
        this.$router.push({ query });
      }

      if (buttonData.field == "DetailsClose") {
        this.showDialog = null;
      }

      if (buttonData.field == "VehicleDetailsDialogClose") {
        buttonData.field = "DetailsClose";
        this.$emit("withButtonPressed", buttonData);  
        this.mainScreen();
      }
    },

    actionIsDetails() {
      var query = Object.assign({}, this.$route.query);
      let action = query['vehicleListAction'];
      return action === 'detailsDialog';
    },

    urlQueryParams() {
      return Object.assign({}, this.$route.query);
    },
  }

}

</script>