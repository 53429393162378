<template>
  <DetailsDisplayView 
    :itemId="itemId" 
    :isDisplay="isDisplay"
    :selectedInspectionIds="selectedInspectionIds"
    :totalItems="totalItems "
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue">
  </DetailsDisplayView>
</template>

<script>
import DetailsDisplayView from '../repair/RepairView.vue';
export default {
  name: 'inspection-list-display-row',
  components: {
    DetailsDisplayView
  },
  props: {
    itemId: { type: String, default: "" },
    isDisplay: { type: Boolean, default: false },
    selectedInspectionIds: { type: Array, default: () => [] },
    totalItems: { type: Number, default: 0 },
    
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
