import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import VehicleRow from "../../../../../shared/cell/vehicle/VehicleRow";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let query = this.model().panel().$route.query;
    let row = null;
    let even = true;
    let vehicle = null;

    let list = this.model().vehicles();
    for (let index = 0; index < list.length; index++) {
      vehicle = list[index];
      row = new VehicleRow().withVehicle(vehicle);
      row
        .withIsAdmin(this.model().isAdmin())
        .withIsSelect(this.model().panel().usedFor == "selection")
        .withIndex(index + ((this.model().pageNumber(query) - 1) * this.model().pageSize(query, "vehicle")))
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
      new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "vehicle")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(query["page"] ? query["page"] : "1")
                .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let user = this.model().user();
    let userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    if (userId) {
      //
    }
    let buttonsRow = new RowDisplayRow()
      .withChild(new LabelDisplayRow().withWidth(3).withLabel("Vehicles and Trailers"))
      .withChild(new SpacerDisplayRow().withWidth(6));

    let addRow = null;
    let exportRow = null;
    if (this.model().isAdminDisplay()) {
      addRow = new SpacerDisplayRow().withWidth(3);
      exportRow = new SpacerDisplayRow().withWidth(2);
    } else {
      if (this.model().panel().usedFor == "selection") {
        addRow = new SpacerDisplayRow().withWidth(3);
        exportRow = new SpacerDisplayRow().withWidth(2);
      } else {
        addRow = new ButtonDisplayRow().withWidth(3).withName("Add Vehicles or Trailer").withField("Add").withBackgroundPrimaryAction()
        exportRow = new ButtonDisplayRow().withWidth(2).withName("Export").withField("Export");
      }
    }

    buttonsRow
      .withChild(exportRow)
      .withChild(addRow);

    buttonsRow
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"));

    if (this.model().panel().embedded) {
      buttonsRow = null;
    }
    this.addHeader(
      new RowDisplayRow()
        .withChild(
          new BoxDisplayRow()
            .withWidth("15")
            .withChild(buttonsRow)
            .withChild(new RowDisplayRow()
              .withChild(
                new BoxDisplayRow()
                  .withWidth("15")
                  .withBorder(true)
                  .withChild(this.model().panel().embedded ? null : new LabelDisplayRow().withLabel("Filters"))
                  .withChild(new RowDisplayRow()
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("State").withValueWidth("15"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStates").withField("State").withDefaultValue("all").withSelected(query["state"] === "all"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("goodStates").withField("State").withDefaultValue("active").withSelected(query["state"] === "active" || !query["state"]))
                    .withChild(new ButtonDisplayRow().withWidth("2").withName("Removed").withKey("minorStates").withField("State").withDefaultValue("inactive").withSelected(query["state"] === "inactive"))
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Type"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allTime").withField("Type").withDefaultValue("all").withSelected(query["type"] === "all" || !query["type"]))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Vehicles").withKey("vehicle").withField("Type").withDefaultValue("vehicle").withSelected(query["type"] === "vehicle"))
                    .withChild(new ButtonDisplayRow().withWidth("2").withName("Trailers").withKey("trailer").withField("Type").withDefaultValue("trailer").withSelected(query["type"] === "trailer"))
                    .withChild(new SpacerDisplayRow().withWidth("4"))
                  )
                  .withChild(new RowDisplayRow()
                    //   .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Usage Type"))
                    //   .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allUsage").withField("UsageType").withDefaultValue("all").withSelected(query["usageType"] === "all" || !query["usageType"]))
                    //   .withChild(new ButtonDisplayRow().withWidth("2").withName("Commercial").withKey("commercialUsage").withField("UsageType").withDefaultValue("commercial").withSelected(query["usageType"] === "commercial"))
                    //   .withChild(new ButtonDisplayRow().withWidth("2").withName("Non-Commercial").withKey("nonCommercialUsage").withField("UsageType").withDefaultValue("noncommercial").withSelected(query["usageType"] === "noncommercial"))
                    //   .withChild(new SpacerDisplayRow().withWidth("9"))
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Safety Date"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allSafety").withField("SafetyDate").withDefaultValue("all").withSelected(query["safetyDate"] === "all" || !query["safetyDate"]))
                    .withChild(new ButtonDisplayRow().withWidth("2").withName("Expiring Soon").withKey("expiringSoon").withField("SafetyDate").withDefaultValue("expiringSoon").withSelected(query["safetyDate"] === "expiringSoon"))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Expired").withKey("expired").withField("SafetyDate").withDefaultValue("expired").withSelected(query["safetyDate"] === "expired"))
                    .withChild(new SpacerDisplayRow().withWidth("10"))
                  )

                  .withChild(new RowDisplayRow()
                    .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
                    .withChild(new StringUpdateRow().withWidth("5")
                      .withShowLabel(false).withField("Search")
                      .withKey("Search").withName("Search")
                      .withDefaultValue(query["search"] ? query["search"] : ""))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
                    .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
                    .withChild(new SpacerDisplayRow().withWidth("7"))
                  )
              )
            )
            .withChild(new RowDisplayRow()
              .withChild(new BoxDisplayRow()
                .withWidth("15")
                .withBorder(true)
                .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "vehicle")))
                  .withChild(new StringDisplayRow().withWidth("4"))
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Items/Page")
                    .withField("PageSize")
                    .withShowLabel(false)
                    .withDefaultValue(this.model().pageSize(query, "vehicle"))
                    .withOptions(ContentUtils.PAGE_SIZES()))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                  .withChild(new StringListUpdateRow().withWidth("2")
                    .withShowLabel(false)
                    .withName("Jump to Page")
                    .withField("PageIndex")
                    .withDefaultValue(query["page"] ? query["page"] : "1")
                    .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "vehicle"))))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
                )
              )
            )
        )
    );

    let box = new BoxDisplayRow();
    if (this.model().panel().usedFor == "selection") {
      box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth(1).withTitle("Name").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(1).withTitle("Plate").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(1).withTitle("Status").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(3).withTitle("VIN").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(2).withTitle("Jurisdiction").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(1).withTitle("Year").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(2).withTitle("Odometer").withAlignLeft())
        .withChild(new TitleDisplayRow().withWidth(2).withTitle("Make and Model").withAlignLeft())
      );
    } else {
      box
        .withChild(new RowDisplayRow()
          .withBackgroundTitle()
          .withChild(new TitleDisplayRow().withWidth(3).withTitle("Picture").withAlignLeft())
          .withChild(new RowDisplayRow().withWidth(12)
            .withChild(new TitleDisplayRow().withWidth("4").withTitle("Details").withAlignLeft())
            .withChild(new TitleDisplayRow().withWidth("4").withTitle("Plate / Vin").withAlignLeft())
            .withChild(new TitleDisplayRow().withWidth("4").withTitle("Safety").withAlignLeft())
            .withChild(new TitleDisplayRow().withWidth("3").withTitle("Extra").withAlignLeft())
          )
        );
    }
    this.addHeader(box);
  }

  makeAndModel(vehicle) {
    let value = "";
    let makeSet = false;
    if (StringUtils.isNotEmpty(vehicle.make())) {
      value = vehicle.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(vehicle.model())) {
      if (makeSet) {
        value = value + " / " + vehicle.model();
      } else {
        value = "<Not Set>" + "/ " + vehicle.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + "<Not Set>"
      }
    }
    return value;
  }

  categoryName(vehicle) {
    let category = vehicle.category().find();
    let list = ContentUtils.CATEGORIES();
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item['@rid'] == category.id()) {
        return item['name'];
      }
    }
    return category.name();
  }

}