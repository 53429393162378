import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";
import ListViewUtils from "./ListViewUtils";

import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";

import InspectionMap from '@/domain/model/inspection/InspectionMap.js';
// import Utils from '@/views/portal/inspections/Utils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import RcObject from "@/domain/common/RcObject.js";
import RcResponseEvent from "../../../../../../domain/model/events/RcResponseEvent";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._vehicleId = null;
    this._total = 0;
    this._inspections = [];
    this._pages = [];
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "inspection");

    if (valueData["field"] == "SortBy") {
      this.withPageSortBy(valueData, "inspection");
      this.addQueryParams(valueData, "sortBy");
    }
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);

    if (StringUtils.startsWith(buttonData["field"], "Filter.")) {
      this.withPageFilter(buttonData, "inspection");
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadInspections(forceUpdate = true) {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Inspections");

    let timeFilter = this.pageFilter(query, "Filter.Time", "last30Days", "inspection");
    var filter = new InspectionListFilter();
    var time = timeFilter;
    if (!isNaN(time)) {
      time = "(" + time + ")";
    }
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }

    var context = {};

    var pageNumber = query["page"] ? (query["page"] - 1) : 0;
    let statusFilter = this.pageFilter(query, "Filter.Status", "all", "inspection");

    filter
      .withDateRange(time)
      .withDefectType(statusFilter)
      .withPagination(this.pageSize(query, "inspection"), pageNumber)
      .done();

    let sortByValue = this.pageSortBy(query, "createdDateZa", "inspection");
    context["sortBy"] = sortByValue
    filter
      .withSortBy(context["sortBy"])
      .done();

    var user = this.user();
    if (!user.isNew()) {
      var employeeMap = this.domain().employees().findByUserId(user.id());
      var employee = employeeMap.findByCompanyId(this._companyId);
      if (this.isAdmin()) {
        employee = employeeMap.first();
      }
      if (!employee.isNew()) {
        filter
          .withEmployee(employee)
          .done();
      }
    }

    var vehicle = this.vehicle();
    if (!vehicle.isNew()) {
      filter
        .withVehicle(vehicle)
        .done();
    }

    filter
      .withCompany(this._companyId)
      .done();

    filter.remove(RcObject.ID);

    context["model"] = this;
    context["startTime"] = new Date();
    context["fetched"] = true;
    context["filter"] = JSON.stringify(filter);
    let filterKey = context["filter"];
    let subdomain = this.domain().inspections();

    if (!subdomain.hasFilter(filterKey) || forceUpdate) {
      var event = this.domain().events()
        .inspections()
        .list(filter, true, true);
      event.with("populate", true);
      event.send(this.loadedItemsListener, context);

    } else {
      context["fetched"] = false;
      let data = subdomain.getFilter(filterKey);
      let size = subdomain.getFilterSize(filterKey);
      let response = new RcResponseEvent(this.domain(), {});
      let payload = response.payload();
      payload.withCount(size);
      payload.put(InspectionMap.MODEL_NAME, data);
      response.withPayload(payload);
      this.loadedItemsListener(response, context);
    }
  }

  loadedItemsListener(responseEvent, context) {
    context["loadTime"] = new Date();
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(InspectionMap.MODEL_NAME);
      var map = new InspectionMap(model.domain(), payloadMap);
      if (!context["fetched"]) {
        map = map.find();
      }
      let sortBy = context.sortBy;
      model._inspections = map.sortBy(sortBy);
      context["sortTime"] = new Date();
      model.domain().inspections().putFilter(context["filter"], map, payload.count());
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "inspection"));
    model.delayedClearAndBuildTable(context);
  }

  delayedClearAndBuildTable(context) {
    let model = context["model"];
    setTimeout(model.loadedInspectionsFinished, 250, context);
  }

  loadedInspectionsFinished(context) {
    let model = context["model"];
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);

    model.panel().showDialogIfRequired();
  }

  user() {
    this._userId = ContentUtils.baseDecode(this.panel().$route.query.userId);
    var user = this.domain().users().findById(this._userId);
    return user;
  }

  vehicle() {
    this._vehicleId = ContentUtils.baseDecode(this.panel().$route.query.vehicleId);

    if (StringUtils.isEmpty(this._vehicleId)) {
      this._vehicleId = ContentUtils.baseDecode(this.panel().$route.query.equipmentId);
    }
    var vehicle = this.domain().vehicles().findById(this._vehicleId);
    return vehicle;
  }

  inspections() {
    return this._inspections;
  }

  inspectionIds() {
    return this._inspections.map(inspection => ContentUtils.baseEncode(inspection.id()));
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

}