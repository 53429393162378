
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import AddViewUtils from "./AddViewUtils";

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
import ChecklistMap from '@/domain/model/checklist/CheckListMap.js';

import ScheduleListFilter from "@/domain/model/schedule/ScheduleListFilter.js";
import VehicleListFilter from "@/domain/model/vehicle/VehicleListFilter.js";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import BooleanUtils from "../../../../../../../utils/BooleanUtils";

export default class AddViewModel extends RcTableViewModel {

  static Fields = {
    SaveButton: "save",
    SaveAnotherButton: "saveAnother",
    CancelButton: "cancel",
    CancelConfirm: "cancelConfirm",
  }

  static Values = {
    States: {
      Loading: "loading",
      Loaded: "loaded",
      Saving: "saving",
      Cancelling: "cancelling",
    }
  }

  constructor(panel, vehicle) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._vehicleId = panel.$route.query.vehicleId;
    this._total = 0;
    this._error = null;
    this._state = AddViewModel.Values.States.Loading;
    this._vehicle = vehicle;
    this._vehicles = [];
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
    this._savedAnother = false;
  }

  start() {
    super.start();
  }

  isAdmin() {
    return super.isAdmin();
  }

  withValue(valueData) {
    super.withValue(valueData, "vehicle");

    if (!valueData) {
      return;
    }

    this.domain().logger().info("Received: {0}", [JSON.stringify(valueData, null, 2)]);

    var field = valueData['field'];
    var fieldValue = valueData['value'];
    var objectId = valueData['id'];
    if (!objectId) {
      return;
    }

    if (field === Vehicle.FIELDS.SCHEDULE) {
      var schedule = this.domain().schedules().findById(fieldValue);
      this._vehicle.withSchedule(schedule.pointer()).done();
      this.rebuildTableData();

    } else if (field === Vehicle.FIELDS.Checklists) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withChecklists(new ChecklistMap(this.domain()));
      } else {
        var checklist = this.domain().checklists().findById(fieldValue);
        this._vehicle.withChecklist(checklist.pointer()).done();
      }

    } else if (field == Vehicle.FIELDS.ANNUAL_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withAnnualDate(0);
      } else {
        this._vehicle.put(field, fieldValue);
      }

    } else if (field == Vehicle.FIELDS.START_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withStartDate(0);
      } else {
        this._vehicle.put(field, fieldValue);
      }

    } else if (field == Vehicle.FIELDS.END_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withEndDate(0);
      } else {
        this._vehicle.put(field, fieldValue);
      }

    } else if (field == Vehicle.FIELDS.PLATE_EXPIRY_DATE) {
      if (StringUtils.isNull(fieldValue)) {
        this._vehicle.withPlateExpiryDate(0);
      } else {
        this._vehicle.put(field, fieldValue);
      }

    } else if (field == "picture") {
      this._vehicle.withImageData(fieldValue);

    } else if (field == "RequiresSchedule") {
      if (BooleanUtils.toBoolean(valueData["value"])) {
        this._vehicle.withUsageTypeCommercial();
      } else {
        this._vehicle.withUsageTypeNonCommercial();
      }
      this.withButtonPressed(valueData);

    } else if (field == "HubometerSelect") {
      this.withButtonPressed(valueData);

    } else if (field == Vehicle.FIELDS._CATEGORY) {
      let category = this.domain().categorys().findById(fieldValue);
      this._vehicle.withCategory(category);
      this.rebuildTableData();

    } else {
      this._vehicle.put(field, fieldValue);

    }
    this.log().info("{0}", [JSON.stringify(this._vehicle.data, null, 2)]);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    if (buttonData["field"] === "DetailsClose") {
      buttonData["refresh"] = this._savedAnother;
      this.panel().$emit("withButtonPressed", buttonData);
    }

    if (buttonData["field"] === "Save") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
      } else {
        this.withStateContentSaving();
        this.panel().reloadPage();
        this._vehicle.withCompany(this.domain().session().company());
        this.event = this.domain().events().vehicleSave(this._vehicle.data);
        this.event.send(this.saveListener, this);
      }
    }

    if (buttonData["field"] === "SaveAdd") {
      if (!this.areRequirementsMet()) {
        this.panel().showMissingRequirements = true;
      } else {
        this.withStateContentSaving();
        this.panel().reloadPage();
        this._vehicle.withCompany(this.domain().session().company());
        this.event = this.domain().events().vehicleSave(this._vehicle.data);
        this.event.send(this.saveAnotherListener, this);
      }
    }

    if (buttonData["field"] === "RequiresSchedule") {
      this.rebuildTableData();
      this.panel().paint();
    }

    if (buttonData["field"] === "HubometerSelect") {
      this._vehicle.withHubometerUsed(!this._vehicle.isHubometerUsed());
      this.rebuildTableData();
    }
  }

  withStateContentSaving() {
    this.withState("contentSaving");
  }

  isStateContentSaving() {
    return this.state() === "contentSaving";
  }

  withStateContentSaved() {
    this.withState("contentSaved");
  }

  isStateContentSaved() {
    return this.state() === "contentSaved";
  }

  saveAnotherListener(response, context) {
    if (!response) {
      return;
    }
    var model = context;
    model.withStateContentSaved();
    model.panel().reloadPage();
    model._savedAnother = true;
    model.log().info("Vehicle saved");
    setTimeout(function () {
      model.resetPage(model);
    }, 500);
  }

  resetPage(model) {
    model._vehicle = new Vehicle(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }

  closePage(model) {
    model.panel().close(this._savedAnother);
  }

  saveListener(response, context) {
    if (!response) {
      return;
    }

    var model = context;
    model.withStateContentSaved();
    model.panel().reloadPage();
    model._savedAnother = true;

    model.log().info("Vehicle saved returning");
    setTimeout(function () {
      model.closePage(model);
    }, 500);
  }

  buildRowData(tableData) {
    var utils = new AddViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  loadData() {
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;

    this.log().info("Loading view data");

    var scheduleFilter = new ScheduleListFilter();
    scheduleFilter
      .withPagination(0, 20)
      .done();
    scheduleFilter
      .withCompany(this.domain().session().company().id())
      .done();

    var event = this.domain().events().schedules().list(scheduleFilter);
    event.send(this._loadScheduleData, this);

    var filter = new ChecklistListFilter();
    filter
      .withPagination(0, 10)
      .done();

    filter
      .withCompany(this.domain().session().company().id())
      .done();

    var checklistsEvent = this.domain().events().checklists().list(filter);
    checklistsEvent.send(this._loadChecklistData, this);

    var id = this.vehicle().id();

    var vehicleFilter = new VehicleListFilter();
    vehicleFilter
      .withPagination(20, 0)
      .withCompany(this.domain().session().company().id())
      .withIds([id])
      .done();

    var vehicleEvent = this.domain().events().vehicles().details(vehicleFilter);
    vehicleEvent.send(this._loadVehicleData, this);
  }

  _loadScheduleData(response, context) {
    if (response) {
      //
    }
    var model = context;
    model.log().info("Loaded schedule view data");

    if (model.vehicle().isNew()) {
      model._scheduleDataLoaded = true;
      if (model._checklistDataLoaded && model._scheduleDataLoaded) {
        //
      }
    }
  }

  _loadChecklistData(response, context) {
    if (response) {
      //
    }
    var model = context;
    model.log().info("Loaded checklist view data");

    if (model.vehicle().isNew()) {
      model._checklistDataLoaded = true;
      if (model._checklistDataLoaded && model._scheduleDataLoaded) {
        //
      }
    }
  }

  _loadVehicleData(response, context) {
    if (response) {
      //
    }
    var model = context;
    model.log().info("Loaded vehicle view data");
    this._timer = setTimeout(function () {
      model._delayedShowVehicleData(model);
    }, 100);
  }

  _delayedShowVehicleData(model) {
    model._vehicle = model.domain().vehicles().findById(model.vehicle().id());
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  vehicle() {
    return this._vehicle;
  }

  areRequirementsMet() {
    var met = true;
    var vehicle = this._vehicle;
    if (StringUtils.isEmpty(vehicle.name())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(vehicle._category())) {
      if (vehicle.category().isNew()) {
        met = false
      }
    }
    if (met && StringUtils.isEmpty(vehicle.brakeType())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.odometerType())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.year())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.plate())) {
      met = false
    }
    if (met && StringUtils.isEmpty(vehicle.jurisdiction())) {
      met = false
    }
    if (vehicle.isUsageTypeCommercial()) {
      if (met && vehicle.schedule().isNew()) {
        met = false
      }
    } else {
      if (met && vehicle.schedule().isNew()) {
        if (met && vehicle.checklists().isEmpty()) {
          met = false
        }
      }
    }

    return met;
  }
}