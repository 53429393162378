<template>
  <DisplayView 
    :companyId="companyId" 
    :page="page"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"></DisplayView>
</template>

<script>
import DisplayView from './DisplayView.vue';
export default {
  name: 'admin-views-company-list-details-row',
  components: {
    DisplayView,
  },
  props: {
    companyId: { type: String, default: "" },
    page: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
