<template>
    <Row>
      <Column :width="15" >
        <div id="detailsModal" class="rc-modal-dialog">
          <!-- Modal content -->
          <div class="rc-modal-content-dialog-wide">
            <div class="rc-modal-header-dialog">
              <Box :key="redraw">
                <RepairImageRow v-if="this.showDialog == 'imageDialog'" :itemId="this.itemId" :commentId="commentId" :incomingImageIndex="this.incomingImageIndex" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withFieldValue"></RepairImageRow>
                <PdfRow v-else-if="this.showDialog == 'pdfDialog'" :itemId="this.itemId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withFieldValue"></PdfRow>
                <Row 
                  v-for="(row, rowIndex) in viewHeaderData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
  
            <div class="rc-modal-body-dialog">
              <div class="rc-modal-body-inner-dialog rc-border-thin">
                <Box v-if="!loading" :key="redraw + 1">
                  <Row 
                    v-for="(row, rowIndex) in viewBodyData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
                <Box v-if="loading">
                  <Row 
                    v-for="(row, rowIndex) in loadingData" 
                    :key="rowIndex" >
                      <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                  </Row>
                </Box>
              </div>
            </div>
  
            <div class="rc-modal-footer-dialog">
              <Box :key="redraw + 2">
                <Row 
                  v-for="(row, rowIndex) in viewFooterData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>
        </div>
      </Column>
    </Row>
</template>

<script> 

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import DateUtils from '@/utils/DateUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow";
import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";

import ScheduleHeaderDisplayRow from "@/portals/shared/cell/schedule/header/ScheduleHeaderDisplayRow.js";
import ScheduleQuestionDisplayRow from "@/portals/shared/cell/schedule/question/ScheduleQuestionDisplayRow.js";
import ScheduleQuestionOptionDisplayRow from "@/portals/shared/cell/schedule/question/option/ScheduleQuestionOptionDisplayRow.js";
import ScheduleQuestionOptionUpdateRow from "@/portals/shared/cell/schedule/question/option/ScheduleQuestionOptionUpdateRow.js";
import ScheduleQuestionRemarksDisplayRow from "@/portals/shared/cell/schedule/question/remark/ScheduleQuestionRemarksDisplayRow.js";

import ChecklistHeaderDisplayRow from "@/portals/shared/cell/checklist/header/ChecklistHeaderDisplayRow.js";
import ChecklistItemDisplayRow from "@/portals/shared/cell/checklist/item/ChecklistItemDisplayRow.js";
import ChecklistItemOptionDisplayRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionDisplayRow.js";
import ChecklistItemRemarksDisplayRow from "@/portals/shared/cell/checklist/item/remark/ChecklistItemRemarksDisplayRow.js";

import CommentImageDisplayRow from "@/portals/shared/cell/comment/CommentImageDisplayRow.js";
import Comment from "@/domain/model/comment/Comment.js";
import RepairResult from '@/domain/model/inspection/repair/result/RepairResult.js'

import Inspection from "@/domain/model/inspection/Inspection.js";
import SignatureUpdateRow from "@/portals/shared/cell/signature/SignatureUpdateRow.js";
import SignatureDisplayRow from "@/portals/shared/cell/signature/SignatureDisplayRow.js";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";

import RepairMap from '@/domain/model/answer/repair/RepairMap.js'
import AnswerMap from '@/domain/model/answer/AnswerMap.js'
import ResultMap from '@/domain/model/inspection/result/ResultMap.js'
import Repair from '@/domain/model/answer/repair/Repair.js'

import RepairImageRow from "./image/RepairImageRow";
import PdfRow from "../pdf/PdfRow";
import RcResponseEvent from "@/domain/model/events/RcResponseEvent";
import RcTableViewModel from '../../../../../../shared/table/RcTableViewModel';

export default {
  name: "vehicle-list-repair-row",
  components: {
    CellFactory,
    Box, Column, Row,
    RepairImageRow, 
    PdfRow,
  },

  props: {
    itemId: { type: String, default: "" },
    isDisplay: { type: Boolean, default: false },
    selectedInspectionIds: { type: Array, default: () => [] },
    totalItems: { type: Number, default: 0 },
  },

  data() {
    return {
        redraw: 1,
        redrawMain: 10000,

        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,

        showMissingRequirements: false,
        viewHeaderData: [],
        viewBodyData: [],
        viewFooterData: [],
        viewModel: null,

        inspection: null,
        vehicle: null,
        company: null,

        isSigned: false,

        modifiedRepairs: {},
        modifiedAnswers: {},
        modifiedResults: {},

        allResults: {},
        vehicleResults: {},
        trailerResults: {},

        resultAnswers: {},
        answerRepairs: {},

        repairRequired: true,

        commentId: "",
        currentInspectionIndex: 0,
        incomingImageIndex: 0,

        loading: true,
        loadingData: [],

        workOrder: "",
        remark: "",

        kvps: {},
        error: null,
        showDialog: null,
    }
  },

  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },

  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route(to, from) {
      if (to && from) {
        if (to.query["inspectionRepairAction"] == "pdfDisplay") {
          this.showDialog = "pdfDialog";
          this.paint();
          this.mainScreen();
        }
        if (to.query["inspectionRepairAction"] == "imageDisplay") {
          this.showDialog = "imageDialog";
          this.paint();
          this.mainScreen();
        }
        if (!to.query["inspectionRepairAction"]) {
          this.showDialog = null;
          this.paint();
          this.mainScreen();
        }
      }
    }
  },

  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },

  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany',
                   ]),
    
    start() {
      this.viewModel = new RcTableViewModel(this, false);
      this.viewModel.withItemCount(this.totalItems);
      this.inspection = new Inspection(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.inspection = this.domain.inspections().findById(id).copy();
        this.vehicle = this.inspection.vehicle().find();
        this.company = this.inspection.company().find();

        if (this.isDisplay) {
          for (var index = 0; index < this.selectedInspectionIds.length; index++) {
            if (this.selectedInspectionIds[index] == this.itemId) {
              this.currentInspectionIndex = index;
              break;
            }
          }
        }
      }
      this.loadItemAndShowLoading(true);
    },

    loadItemAndShowLoading(forceUpdate = false) {
      this.loadingScreen();
      this.loadItem(forceUpdate);
    },

    loadItem: function(forceUpdate = false) {
      this.modifiedRepairs = new RepairMap(this.domain);
      this.modifiedAnswers = new AnswerMap(this.domain);
      this.modifiedResults = new ResultMap(this.domain);
      this.resultAnswers = {};
      this.answerRepairs = {};

      let context = {};
      context["startTime"] = new Date();
      context["fetched"] = true;
      context["filter"] = this.itemId;
      context["model"] = this;
      let filterKey = context["filter"];
      let subdomain = this.domain.inspections();
      if (!subdomain.hasFilter(filterKey) || forceUpdate) {
        var event = this.domain.events().inspectionDetails(ContentUtils.baseDecode(this.itemId));
        event.send(this.loadedItemsListener, context);
      } else {
        context["fetched"] = false;
        let data = subdomain.getFilter(filterKey);
        let size = subdomain.getFilterSize(filterKey);
        let response = new RcResponseEvent(this.domain, {});
        let payload = response.payload();
        payload.withCount(size);
        payload.put(ResultMap.MODEL_NAME, data);
        response.withPayload(payload);
        this.loadedItemsListener(response, context);
      }
    },

    loadedItemsListener: function(event, context) {
      var model = context.model;
      if (event.error()) {
        model.error = event.error();
      } else {
        var payload = event.payload();
        var payloadMap = payload.valuesFor(ResultMap.MODEL_NAME);
        let map = new ResultMap(model.domain, payloadMap);
        if (!context["fetched"]) {
          map = map.find();
        }
        model.domain.inspections().putFilter(context["filter"], map, payload.count());
        model.allResults = map;
        model.vehicleResults = model.allResults.vehicle();
        model.trailerResults = model.allResults.trailers();
      }
      const id = ContentUtils.baseDecode(this.itemId);      
      this.inspection = this.domain.inspections().findById(id).copy();
      this.vehicle = this.inspection.vehicle().find();
      this.company = this.inspection.company().find();

      delete context["model"];
      context["loadTime"] = new Date();
      this.log().info("{0}", [JSON.stringify(context,null,2)]);
      this.loading = false;
      setTimeout(model.mainScreen, 100);
    },

    loadingScreen() {
      if (this.loadingData.length == 0) {
        this.loadingData.push(new BoxDisplayRow()
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("5"))
            .withChild(new BoxDisplayRow().withBorder(true).withWidth("5").withChild(new LoadingDisplayRow().withMessage("Loading Inspection Details").withKey("progress").withId("progress")))
            .withChild(new SpacerDisplayRow().withWidth("5"))
          ));
      }

      this.loading = true;
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      this.paint();
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      if (this.isDisplay) {
        var hasUnfixedDefects = this.inspection.hasUnfixedDefect();

        var buttonRow = new RowDisplayRow();
        let query = Object.assign({}, this.$route.query);
        let pageNumber = query["page"] ? query["page"] : 1;
        let pageSize = this.viewModel.pageSize(query, "inspection");

        buttonRow.withChild(new StringDisplayRow()
                        .withWidth(3)
                        .withValue("Inspection " + (((pageNumber - 1) * pageSize) + this.currentInspectionIndex + 1) + " of " + (this.totalItems))
                        .withAlignLeft()
                        );
        if (hasUnfixedDefects) {
          buttonRow.withChild(new ButtonDisplayRow().withWidth("2").withId(this.inspection.id()).withName("Repair").withField("Repair"));
        } else {
          buttonRow.withChild(new SpacerDisplayRow().withWidth("2"));
        }

        buttonRow
                .withChild(new ButtonDisplayRow().withWidth("3").withId(this.inspection.id()).withName("Print/Download").withField("Print"))
                .withChild(new ButtonDisplayRow().withWidth("2").withId(this.inspection.id()).withName("Email").withField("Email"))
                .withChild(new ButtonDisplayRow().withWidth("3").withId(this.inspection.id()).withName("+ Remarks").withField("Remarks"))
                .withChild(new ButtonDisplayRow().withWidth("2").withId(this.inspection.id()).withName("Refresh").withField("Refresh"))

        this.viewHeaderData.push(new BoxDisplayRow()
                .withBorder(true)
                .withChild(buttonRow)
              );
      }
      this.viewHeaderData.push(new RowDisplayRow()
          .withChild(new TitleDisplayRow().withTitle("Inspection").withWidth("15")));

      let footerBox = new BoxDisplayRow().withBorder(true);
      let button = new ButtonDisplayRow().withWidth("2").withName("Cancel").withField("DetailsClose");
      if (this.isDisplay) {
        button.withName("Close");
      }
      let footerRow = new RowDisplayRow()
                .withChild(button)
                .withChild(new StringDisplayRow().withWidth("9"))

      if (this.isDisplay) {
        footerRow = new RowDisplayRow()
                .withChild(button)
                .withChild(new StringDisplayRow().withWidth(11));
        
        let back = new ButtonDisplayRow().withWidth("1").withName("<").withField("Previous");
        let next = new ButtonDisplayRow().withWidth("1").withName(">").withField("Next");
        footerRow.withChild(back);
        footerRow.withChild(next);
      } else {
        footerRow.withChild(new SpacerDisplayRow().withWidth("2"))
        footerRow.withChild(new ButtonDisplayRow().withWidth("2").withName("Save").withField("Save"))
      }

      footerBox.withChild(footerRow)
      this.viewFooterData.push(footerBox);

      let cvorBox = null;

      if (StringUtils.isNotEmpty(this.company.cvorNumber())) {
        cvorBox = new LabelValueDisplayRow().withWidth("15")
                        .withLabelWidth(3)
                        .withLabel(this.MC.Inspection.Details.CvorNumberLabel.value())
                        .withValue(this.company.cvorNumber());
      }

      let companyBox = new RowDisplayRow()
          .withChild(new TitleDisplayRow().withWidth("15")
            .withTitle(this.MC.Inspection.Details.CompanyLabel.value()))

          .withChild(new LabelValueDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.NameLabel.value())
            .withValue(this.company.name()))

          .withChild(new LabelValueDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.AddressLabel.value())
            .withValue(this.company.address().find().location()))

          .withChild(cvorBox)

      let inspectionDetailsBox = new RowDisplayRow()
          .withChild(new TitleDisplayRow().withWidth("15")
            .withTitle(this.MC.Inspection.Details.Title.value()))
            
          .withChild(new LabelValueDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.DateLabel.value())
            .withValue(this.inspection.createdDateDisplay()))

          .withChild(new LabelValueDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.AddressLabel.value())
            .withValue(this.inspection.location()))

          .withChild(new LabelValueDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.TripTypeLabel.value())
            .withValue(this.inspection.isPreTrip() ? 
              this.MC.Inspection.Details.PreTripValue.value() : 
                this.MC.Inspection.Details.PostTripValue.value()))

          .withChild(new StatusDisplayRow().withWidth("15")
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.StatusLabel.value())
            .withValue(this.inspection.defectTypeDisplay())
            .withId(this.inspection.id()))

      this.viewBodyData.push(companyBox);
      this.viewBodyData.push(inspectionDetailsBox);

      let vehicleTitleRow = new TitleDisplayRow().withWidth("15")
             .withTitle(this.MC.Inspection.Details.VehicleLabel.value())
      this.viewBodyData.push(vehicleTitleRow);
      this.vehicleBoxMaker(this.vehicle, this.inspection);


      var trailerMap = this.inspection.trailers().find();
      var trailers = trailerMap.sortByName();
      if (this.inspection.hasTrailers()) {
        this.viewBodyData.push(
          new TitleDisplayRow()
            .withTitle(this.MC.Inspection.Details.TrailerLabel.value()),
        );
      }
      for (var trailerIndex = 0; trailerIndex < trailers.length; trailerIndex++) {
        var trailer = trailers[trailerIndex];
        this.viewBodyData.push(new LabelValueDisplayRow()
          .withLabelWidth(3)
          .withLabel(this.MC.Inspection.Details.TrailerNumberLabel.value())
          .withValue(this.MC.Inspection.Details.TrailerNumberValue.value([trailerIndex + 1, trailers.length])))
        this.vehicleBoxMaker(trailer, this.inspection);
      }

      let remarksRow = this.remarksRowMaker(this.inspection);
      let remarksBox = new BoxDisplayRow().withChildren(remarksRow);

      let initialRows = this.initialInspectionRowMaker(this.inspection);
      let initialBox = new BoxDisplayRow().withChildren(initialRows);

      let driverRows = this.driverRowMaker(this.inspection);
      let driverBox = new BoxDisplayRow().withChildren(driverRows);

      let enrouteRows = this.enrouteRowMaker(this.inspection);
      let enrouteBox = new BoxDisplayRow().withChildren(enrouteRows);

      let repairRows = null;
      if (this.isDisplay) {
        repairRows = this.repairsDisplayRowMaker(this.inspection);
      } else {
        repairRows = this.repairsEditRowMaker();
      }
      let repairBox = new BoxDisplayRow().withChildren(repairRows);

      this.viewBodyData.push(remarksBox);
      this.viewBodyData.push(initialBox);
      if (!this.vehicle.isEquipment()) {
        this.viewBodyData.push(driverBox);
      }
      this.viewBodyData.push(enrouteBox);
      this.viewBodyData.push(repairBox);

      this.paint();
    },

    repairsEditRowMaker() {
      let repairTitle = new TitleDisplayRow()
          .withTitle(this.MC.Inspection.Details.RepairTitle.value());
      let dateRow = new LabelValueDisplayRow()
          .withWidth("15")
          .withLabelWidth(3)
          .withLabel(this.MC.Inspection.Details.DateLabel.value())
          .withValue(DateUtils.toDateTime(DateUtils.now()));
      let workOrderRow = new StringUpdateRow()
          .withName("Work Orders")
          .withField("WorkOrders")
          .withDefaultValue(this.workOrder)
          .withWidth("15");
      let repairedByRow = new LabelValueDisplayRow()
          .withWidth("15")
          .withLabel("Repaired By")
          .withValue(this.domain.session().user().find().fullName());

      let signatureRow = new SignatureUpdateRow()
          .withWidth("15")
          .withField("Sign")
          .withMandatoryValue(true)
          .withSignature(this.domain.session().user().find().signature())
          .withSigned(this.isSigned);
        
      let repairRows = [];
      repairRows.push(repairTitle);
      repairRows.push(dateRow);
      repairRows.push(workOrderRow);
      repairRows.push(repairedByRow);
      repairRows.push(signatureRow);
      return repairRows;
    },

    repairsDisplayRowMaker(inspection) {
      //Repairs
      var repairResultMap = inspection.repairResults().find();  
      var repairResults = repairResultMap.list();
      var repairRows = [];
      if (!repairResultMap.isEmpty()) {
        repairRows.push(new TitleDisplayRow()
            .withTitle(this.MC.Inspection.Details.RepairTitle.value()));
      }
      for (var repairIndex = 0; repairIndex < repairResults.length; repairIndex++) {
        var repairResult = repairResults[repairIndex];
        var employee = repairResult.employee().find();
        var repairer = employee.user().find();
        var repairBox = new BoxDisplayRow()
        repairBox
          .withBorder(true)
          .withChild(new LabelValueDisplayRow()
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.RepairIndexLabel.value())
            .withValue(
                StringUtils.format("{0} of {1}",[repairIndex + 1, repairResults.length])
                )
            )
          .withChild(new LabelValueDisplayRow()
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.DateLabel.value())
            .withValue(repairResult.repairDateDisplay()));
        
        var repairMap = repairResult.repairs().find();
        var optionMap = repairMap.options().find();
        var minorMap = optionMap.minors();
        var majorMap = optionMap.majors();
        
        if (!minorMap.isEmpty()) {
          repairBox
            .withChild(new LabelValueDisplayRow()
              .withLabelWidth(3)
              .withLabel(this.MC.Inspection.Details.RepairsMadeLabel.value())
              .withValue(this.MC.Inspection.Details.MinorDefectsLabel.value()));

          this.addQuestionOptions(repairBox, minorMap);
          
          if (!majorMap.isEmpty()) {
            repairBox
              .withChild(new LabelValueDisplayRow()
                .withLabelWidth(3)
                .withLabel("")
                .withValue(this.MC.Inspection.Details.MajorDefectsLabel.value()));

            this.addQuestionOptions(repairBox, majorMap);
          }
        } else {
          repairBox
            .withChild(new LabelValueDisplayRow()
              .withLabelWidth(3)
              .withLabel(this.MC.Inspection.Details.RepairsMadeLabel.value())
              .withValue(this.MC.Inspection.Details.MajorDefectsLabel.value()));        

          this.addQuestionOptions(repairBox, majorMap);
        }

        repairBox
          .withChild(new LabelValueDisplayRow()
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.WorkOrdersLabel.value())
            .withValue(repairResult.workOrders()))
          .withChild(new LabelValueDisplayRow()
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.RepairedByLabel.value())
            .withValue(repairer.fullName()))
          .withChild(new SignatureDisplayRow()
            .withSignature(repairResult.signature()));
        
        repairRows.push(repairBox);
      }
      return repairRows;
    },

    addQuestionOptions(repairBox, optionMap) {
      var options = optionMap.sort();
      for (var index = 0; index < options.length; index++) {
        var option = options[index];
        repairBox.withChild(new ScheduleQuestionOptionDisplayRow()
            .withIndex(option.position())
            .withLabel(option.value())
            );
      }
    },

    enrouteRowMaker(inspection) {
      //Enroute Inspection
      var enrouteRows = [];
      var schedule = inspection.schedule().find();
      var enrouteInspector = inspection.enrouteInspector().find();
      if (!enrouteInspector.isNew()) {
        enrouteRows.push(new TitleDisplayRow()
            .withTitle(this.MC.Inspection.Details.EnrouteByTitle.value()));
    
        enrouteRows.push(
              new LabelValueDisplayRow()
                .withLabelWidth(3)
                .withLabel(this.MC.Inspection.Details.DateLabel.value())
                .withValue(inspection.enrouteInspectionDateDisplay())
              );
        enrouteRows.push(new LabelValueDisplayRow()
            .withLabelWidth(3)
            .withLabel(this.MC.Inspection.Details.EnrouteByLabel.value())
            .withValue(enrouteInspector.find().user().find().fullName()));
    
        enrouteRows.push(
            new LabelValueDisplayRow()
              .withLabelWidth(3)
              .withLabel(this.MC.Inspection.Details.DateLabel.value())
              .withValue(inspection.enrouteInspectionDateDisplay())
            );
    
        enrouteRows.push(new ValueDisplayRow()
            .withValue(this.MC.Inspection.Details.DeclarationLabel.value([schedule.name()])));
        
        enrouteRows.push(new SignatureDisplayRow()
            .withSignature(inspection.enrouteSignature()));

      }
      return enrouteRows;
    },

    driverRowMaker(inspection) {
      //Driver Inspection
      var driverRows = [];
      var driver = inspection.driver().find();
      var inspector = inspection.inspector().find();
      var driverName = "";
      if (!driver.isNew()) {
        driverName = driver.user().find().fullName();
      }
      driverRows.push(new TitleDisplayRow()
          .withTitle(this.MC.Inspection.Details.DrivenByTitle.value()));

      if (driver.isNew()) {
        driverRows.push(
            new LabelValueDisplayRow()
              .withLabelWidth(3)
              .withLabel(this.MC.Inspection.Details.DateLabel.value())
              .withValue(inspection.driverInspectionDateDisplay())
          );
      }
      driverRows.push(new LabelValueDisplayRow()
          .withLabelWidth(3)
          .withLabel(this.MC.Inspection.Details.DrivenByLabel.value())
          .withValue(driverName));
      
      if (driver.id() !== inspector.id() && !driver.isNew()) {
        driverRows.push(new SignatureDisplayRow()
            .withSignature(inspection.driverSignature()));
        driverRows.push(new ValueDisplayRow()
            .withValue(this.MC.Inspection.Details.DeclarationLabel.value([inspection.schedule().find().name()])));
      } else {
        driverRows.push(new SpacerDisplayRow());
      }
      return driverRows;
    },
 
    remarksRowMaker(inspection) {
      var remarkRows = [];
      if (inspection.hasComments()) {
        remarkRows.push( 
          new TitleDisplayRow()
            .withTitle(this.MC.Inspection.Details.RemarksLabel.value())
            );
        let addCommentBox = null;
        if (!this.isDisplay) {
          addCommentBox = new StringUpdateRow()
                .withName("Additional Remarks or Comments")
                .withField("Comment")
                .withDefaultValue(this.remark)
                .withWidth("15");
        }
        var commentMap = inspection.comments().find();
        var comments = commentMap.sorted();
        for (var commentIndex = 0; commentIndex < comments.length; commentIndex++) {
          var comment = comments[commentIndex];
          var commentBox = new BoxDisplayRow();
          commentBox
            .withBorder(true)
            .withChild(
              new LabelValueDisplayRow()
                .withLabel(this.MC.Inspection.Details.RemarkDateLabel.value())
                .withValue(comment.createdDateDisplay())
              )
            .withChild(
              new LabelValueDisplayRow()
                .withLabel(this.MC.Inspection.Details.RemarkByLabel.value())
                .withValue(comment.employee().find().user().find().fullName())
              )
            .withChild(
              new LabelValueDisplayRow()
                .withLabel(this.MC.Inspection.Details.RemarkLabel.value())
                .withValue(comment.text())
              )
            .withChild(addCommentBox);
          if (comment.hasImages()) {
            commentBox
              .withChild(
                  new CommentImageDisplayRow()
                  .withId(comment.id())
                  .withField("CommentImage")
                  .withObject(comment.data)
                  );
          }
          remarkRows.push(commentBox);
        }
      }
      return remarkRows;
    },

    initialInspectionRowMaker(inspection) {
      var schedule = inspection.schedule().find();
      var initialRows = [];
      var inspector = inspection.inspector().find();
      initialRows.push(new TitleDisplayRow()
            .withTitle(this.MC.Inspection.Details.InitialTitle.value()));

      initialRows.push(
          new LabelValueDisplayRow()
            .withLabel(this.MC.Inspection.Details.DateLabel.value())
            .withLabelWidth(3)
            .withValue(inspection.inspectorInspectionDateDisplay())
          );

      initialRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.InspectedByLabel.value())
          .withLabelWidth(3)
          .withValue(inspector.user().find().fullName()));

      
      initialRows.push(new ValueDisplayRow()
          .withValue(this.MC.Inspection.Details.DeclarationLabel.value([schedule.name()])));

      initialRows.push(new SignatureDisplayRow()
          .withSignature(inspection.inspectorSignature())
          );

      return initialRows;
    },

    vehicleBoxMaker(vehicle, inspection) {
       var resultMap = inspection.results().find();
       var results = resultMap.forVehicle(vehicle);
       var initial = results.initial();
       var enroute = results.enroute();
       var odometerLabel = vehicle.isTrailer() ? this.MC.Inspection.Details.HubometerLabel.value() : this.MC.Inspection.Details.OdometerLabel.value() 
 
       let trailerBox = null;
 
       if (vehicle.isTrailer()) {
         var attachedValue = initial.isAttached() ? this.MC.Inspection.Details.YesValue.value() : this.MC.Inspection.Details.NoValue.value();
         var attachedLabel = "Attached";
 
         var dateValue = initial.attachedDateDisplay();
         if (initial.isDetached()) {
           dateValue = initial.detachedDateDisplay();
         }
 
         trailerBox = new RowDisplayRow().withWidth("15")
             .withChild(new LabelValueDisplayRow().withWidth("15")
               .withLabelWidth(3)
               .withLabel(attachedLabel)
               .withValue(attachedValue))
             .withChild(new LabelValueDisplayRow().withWidth("15")
               .withLabelWidth(3)
               .withLabel(initial.isAttached() ? this.MC.Inspection.Details.AttachedOnLabel.value() : this.MC.Inspection.Details.DetachedOnLabel.value())
               .withValue(dateValue))
       }
 
       let vehicleBox = new RowDisplayRow()
           .withChild(new LabelValueDisplayRow().withWidth("15")
             .withLabelWidth(3)
             .withLabel(this.MC.Inspection.Details.NameLabel.value())
             .withValue(this.vehicle.name()))
 
           .withChild(new LabelValueDisplayRow().withWidth("15")
             .withLabelWidth(3)
             .withLabel(this.MC.Inspection.Details.PlateLabel.value())
             .withValue(this.vehicle.plate()))
 
           .withChild(new LabelValueDisplayRow().withWidth("15")
             .withLabelWidth(3)
             .withLabel(this.MC.Inspection.Details.JurisdictionLabel.value())
             .withValue(this.vehicle.jurisdiction()))
 
           .withChild(trailerBox)
 
           .withChild(new LabelValueDisplayRow().withWidth("15")
             .withLabelWidth(3)
             .withLabel(odometerLabel)
             .withValue(this.inspection.odometer()));
 
       var schedule = initial.schedule().find();
 
       let scheduleBox = null;
 
       if (schedule.isNotNew()) {
         var questionMap = schedule.questions().find();
         var questions = questionMap.sort();
 
         scheduleBox = new RowDisplayRow()
             .withChild(new LabelValueDisplayRow().withWidth("15")
               .withLabelWidth(3)
               .withLabel(this.MC.Inspection.Details.ScheduleLabel.value())
               .withValue(schedule.name()))
             .withChild(new ScheduleHeaderDisplayRow().withWidth("15"));
 
         var initialAnswers = initial.answers().find();
         var enrouteAnswers = enroute.answers().find();
 
         for (var questionIndex = 0; questionIndex < questions.length; questionIndex++) {
           var question = questions[questionIndex];
 
           var intialAnswer = initialAnswers.answerFor(question);
           var initialOptionMap = intialAnswer.options().find();
           
           var enrouteAnswer = enrouteAnswers.answerFor(question);
           var enrouteOptionMap = enrouteAnswer.options().find();
 
           var initialOption = initialOptionMap.first();
           var enrouteOption = enrouteOptionMap.first();
           
           var even = StringUtils.isEven(questionIndex);
 
           var sqdr = new ScheduleQuestionDisplayRow()
             .withWidth("15")
             .withLabel(question.name())
             .withPart(question.part())
             .withInitial(initialOption)
             .withEnroute(enrouteOption)
             .withEven(even);
          
           var sqdrBox = new BoxDisplayRow().withWidth("15").withEven(even);
           sqdrBox.withChild(sqdr);

           if (initial.isInitial()) {
             this.addAnswers(sqdrBox, initialAnswers, question, true, true, even, initial);
             this.addAnswers(sqdrBox, initialAnswers, question, false, true, even, initial);
           }
           if (enroute.isEnroute()) {
             this.addAnswers(sqdrBox, enrouteAnswers, question, true, false, even, enroute);
             this.addAnswers(sqdrBox, enrouteAnswers, question, false, false, even, enroute);
           }

           scheduleBox.withChild(sqdrBox);
         }
       } else {
        vehicleBox = new RowDisplayRow()
           .withChild(
              new LabelValueDisplayRow()
                          .withWidth("15")
                          .withLabelWidth(3)
                          .withLabel(this.MC.Inspection.Details.NameLabel.value())
                          .withValue(this.vehicle.name())
            );
       }
 
       this.addInspectionResultDefectType(scheduleBox, initial, enroute);
 
       var checklistResultMap = inspection.checklistResults().find();
       var checklistMap = vehicle.checklists().find();
       let checklistBox = null;
 
       if (checklistResultMap.isNotEmpty()) {
         var sortedChecklists = checklistMap.sortByName();
         for (var index = 0; index < sortedChecklists.length; index++) {
           var checklist = sortedChecklists[index];
           var checklistResult = checklistResultMap.findResultForChecklist(vehicle, checklist);
 
           if (checklistResult.isNew()) {
             continue;
           }
 
           checklistBox = new RowDisplayRow()
               .withChild(new TitleDisplayRow().withWidth("15")
                 .withSubtitle(true)
                 .withTitle(this.MC.Inspection.Details.ChecklistLabel.value()))
               .withChild(new LabelValueDisplayRow()
                 .withWidth("15")
                 .withLabelWidth(3)
                 .withLabel(this.MC.Inspection.Details.NameLabel.value())
                 .withValue(checklist.name()));
 
           if (checklist.hasDescription()) {
             checklistBox.withChild(new LabelValueDisplayRow()
                .withWidth("15")
                .withLabelWidth(3)
                .withLabel(this.MC.Inspection.Details.DescriptionLabel.value())
                .withValue(checklist.description()));
           }
 
           checklistBox.withChild(new ChecklistHeaderDisplayRow().withWidth("15"));
 
           var itemResults = checklistResult.itemResults().find();
           var itemMap = checklist.items().find();
           var sorted = itemMap.sortByIndex();
 
           for (var itemIndex = 0; itemIndex < sorted.length; itemIndex++) {
             var item = sorted[itemIndex];
             var found = itemResults.findForItem(item);
             var initialResult = found.initial();
             var enrouteResult = found.enroute();
 
             even = StringUtils.isEven(itemIndex);
             let checklistItemBox = new BoxDisplayRow().withWidth("15").withEven(even);
             checklistItemBox.withChild(new ChecklistItemDisplayRow()
               .withWidth("15")
               .withIndex(itemIndex)
               .withLabel(item.name())
               .withEven(even));
 
             if (item.isTypeList()) {
               this.addChecklistItemOptionsResultRow(checklistItemBox, initialResult, true, even);
               this.addChecklistItemOptionsResultRow(checklistItemBox, enrouteResult, false, even);
             }
 
             if (item.isTypeText()) {
               this.addChecklistItemTextResultRow(checklistItemBox, initialResult, true, even);
               this.addChecklistItemTextResultRow(checklistItemBox, enrouteResult, false, even);
             }
 
             if(item.isTypeNumber()) {
               this.addChecklistItemNumberResultRow(checklistItemBox, initialResult, true, even);
               this.addChecklistItemNumberResultRow(checklistItemBox, enrouteResult, false, even);
             }

              checklistBox.withChild(checklistItemBox);
           }
         }
       }

      this.viewBodyData.push(vehicleBox);

      if (scheduleBox) {
        this.viewBodyData.push(scheduleBox);
      }
      if (checklistBox) {
        this.viewBodyData.push(checklistBox);
      }
     },

    addChecklistItemOptionsResultRow(box, result, initial, even) {
      if (!box || !result) {
        return;
      }
      
      if (!result.hasResultType()) {
        return;
      }
      
      var optionMap = result.options().find();
      if (optionMap) {
        var sortedOptions = optionMap.sortByIndex();
        for (var optionIndex = 0; optionIndex < sortedOptions.length; optionIndex++) {
          var option = sortedOptions[optionIndex];
          
          if (option) {
            box.withChild(new ChecklistItemOptionDisplayRow()
              .withWidth("15")
              .withIndex(option.index())
              .withLabel(option.name())
              .withCategory(option.category())
              .withInitial(initial)
              .withEven(even)
              );
          }
        }
      }

      if (result.hasRemarks()) {
        box.push(new ChecklistItemRemarksDisplayRow()
          .withWidth("15")
          .withValue(result.remarks())
          .withEven(even)
          );
      }

    },

    addChecklistItemTextResultRow(box, result, initial, even) {
      if (!box || !result) {
        return;
      }
      
      if (!result.hasResultType()) {
        return;
      }
      
      box.withChild(new LabelValueDisplayRow()
          .withWidth("15")
          .withLabel("")
          .withLabelWidth(3)
          .withValue(result.value())
          .withEven(even)
          );
    },
    
    addChecklistItemNumberResultRow(box, result, initial, even) {
      if (!box || !result) {
        return;
      }
      
      if (!result.hasResultType()) {
        return;
      }
      
      box.withChild(new LabelValueDisplayRow()
          .withWidth("15")
          .withLabel("")
          .withLabelWidth(3)
          .withValue(result.value())
          .withEven(even)
          );
    },

    addAnswers(box, answers, question, minors, initial, even, result) {
      var answer = answers.answerFor(question);
      var optionMap = answer.options().find();
      var selectedOptionMap = null;
      if (minors) {
        selectedOptionMap = optionMap.minors();
      } else {
        selectedOptionMap = optionMap.majors();
      }
      var options = selectedOptionMap.sort();
      for (var index = 0; index < options.length; index++) {
        var option = options[index];
        let sqodrBox = new BoxDisplayRow().withWidth("15").withEven(even);
        let isRepaired = answers.isRepaired(question,option);
        let isNewlyRepaired = this.isNewlyRepaired(question, option);
        let sqodr = new ScheduleQuestionOptionDisplayRow()
            .withWidth("15")
            .withIndex(option.position())
            .withLabel(option.value())
            .withCategory(option.category())
            .withInitial(initial)
            .withRepaired(isRepaired || isNewlyRepaired)
            .withEven(even);

        if (!this.isDisplay) {
          sqodr = new ScheduleQuestionOptionUpdateRow()
            .withWidth("15")
            .withIndex(option.position())
            .withLabel(option.value())
            .withCategory(option.category())
            .withInitial(initial)
            .withRepaired(isRepaired || isNewlyRepaired)
            .withEven(even)
            .withField("RepairPart")
            .withMandatoryValue(this.repairRequired)
            .withId([option.id(), answer.id(), result.id()]);
        }
        
        sqodrBox.withChild(sqodr);
        box.withChild(sqodrBox);
      }
      
      if (minors) {
        var remarksMinor = answer.remarksMinor();
        if (!StringUtils.isEmpty(remarksMinor)) {
          box.withChild(new ScheduleQuestionRemarksDisplayRow()
            .withWidth("15")
            .withValue(remarksMinor)
            .withEven(even)
            );
        }
      } else {
        var remarksMajor = answer.remarksMajor();
        if (!StringUtils.isEmpty(remarksMinor)) {
          box.withChild(new ScheduleQuestionRemarksDisplayRow()
            .withWidth("15")
            .withValue(remarksMajor)
            .withEven(even)
            );
        }
      }
    },

    isNewlyRepaired(question, option) {
      var list = this.modifiedAnswers.list();
      for (var index = 0; index < list.length; index++) {
        var answer = list[index];
        if (answer.question().id() === question.id()) {
          var repairs = this.modifiedRepairs;
          return repairs.isRepaired(option);
        }
      }
      return false;
    },

    addInspectionResultDefectType(box, initial, enroute) {
      if (initial.hasSchedule()) {
        this.addInspectionResultDefectTypeRow(box, initial);
      }
      if (enroute.hasSchedule()) {
        this.addInspectionResultDefectTypeRow(box, enroute);
      }
    },

    addInspectionResultDefectTypeRow(box, result) {
      if (!result.hasResultType()) {
        return;
      }
      
      var defect = result.defectType();
      var isInTrip = result.isEnroute();
      var label = this.MC.Inspection.Details.PostTripDefect.value();
      var inspection = this.inspection;

      if (inspection.isPreTrip()) {
        label = this.MC.Inspection.Details.PreTripDefect.value();
      }
      if (isInTrip) {
        label = this.MC.Inspection.Details.InTripDefect.value();
      }
      if (StringUtils.e("None", defect) || StringUtils.isEmpty(defect)) {
        defect = "nodefectsfound";
      }
      if (StringUtils.e("Minor", defect)) {
        defect = "minordefectsfound";
      }
      if (StringUtils.e("Major", defect)) {
        defect = "majordefectsfound";
      }
      
      box.withChild(new StatusDisplayRow()
          .withWidth("15")
          .withLabel(label)
          .withValue(defect));
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Sending"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },

    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        this.domain.logger().info("Received: {0}", [JSON.stringify(valueData)]);
        this.resetMissingValues();

        if (!valueData) {
          return;
        }

        var field = valueData["field"];
        var value = valueData["value"];

        if (field === "Comment") {
          this.remark = value;
        }

        if (field === "WorkOrders") {
          this.workOrder = value;
        }

        this.$emit("withFieldValue", valueData);
      }
    },

    withButtonPressed: function(buttonData) {
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "PopupClose") {
        this.showDialog = null;
      }
      
      if (buttonData["field"] === "Repair") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "RepairPart") {
        this.resetMissingValues();
        this.repairAction(buttonData);
      }

      if (buttonData["field"] === "Save") {
        this.$emit("withButtonPressed", buttonData);
        this.doSendAction();
      }

      if (buttonData["field"] === "Sign") {
        this.isSigned = true;
        this.mainScreen();
      }

      if (this.isDisplay) {
        let query = Object.assign({}, this.$route.query);
        let pageNumber = query["page"] ? query["page"] : 1;
        this.viewModel.setupPages(query, "inspections");
        let pages = this.viewModel.pages();
        if (buttonData["field"] === "Next") {
          if (this.currentInspectionIndex < this.selectedInspectionIds.length - 1) {
            this.currentInspectionIndex++;
            let id = this.selectedInspectionIds[this.currentInspectionIndex];
            query["inspectionId"] = id;
            this.$router.push({ query });
            this.loadItemAndShowLoading(true);
          } else {
            if (pageNumber < pages.length) {
              buttonData["pageForDetails"] = "next";
              buttonData["field"] = "DetailsClose"
              this.$emit("withButtonPressed", buttonData);
            }
          }
        }

        if (buttonData["field"] === "Previous") {
          let query = Object.assign({}, this.$route.query);
          if (this.currentInspectionIndex > 0) {
            this.currentInspectionIndex--;
            let id = this.selectedInspectionIds[this.currentInspectionIndex];
            query["inspectionId"] = id;
            this.$router.push({ query });
            this.loadItemAndShowLoading(true);
          } else {
            if (this.currentInspectionIndex >= 0 && pageNumber > 1) {
              buttonData["pageForDetails"] = "previous";
              buttonData["field"] = "DetailsClose"
              this.$emit("withButtonPressed", buttonData);
            }
          }
        }
      }

      if (buttonData["field"] === "CommentImage") {
        let query = Object.assign({}, this.$route.query);
        query["inspectionRepairAction"] = "imageDisplay";
        query["commentId"] = buttonData["id"];
        this.commentId = buttonData["id"];
        this.incomingImageIndex = buttonData["imageIndex"];
        this.$router.push({ query });
        this.showDialog = "imageDialog";
      }

      if (buttonData["field"] === "CloseDialog") {
        let query = Object.assign({}, this.$route.query);
        delete query["inspectionRepairAction"];
        delete query["commentId"];
        this.$router.push({ query });
        this.showDialog = null;
      }
      
      if (buttonData["field"] === "Print") {
        let query = Object.assign({}, this.$route.query);
        query["inspectionRepairAction"] = "pdfDisplay";
        this.$router.push({ query });
        this.showDialog = "pdfDialog";
      }

      if (buttonData["field"] === "Email") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Remarks") {
        this.$emit("withButtonPressed", buttonData);
      }

      if (buttonData["field"] === "Refresh") {
        this.start();
      }
    },

    repairAction(data) {
      let ids = data.id.split(',');
      var option = this.domain.options().findById(ids[0]);
      var answer = this.modifiedAnswers.getAnswer(ids[1]);
      var result = this.allResults.getResult(ids[2]);

      this.repairRequired = false;
      if (answer.isNew()) {
        answer = this.domain.answers().findById(ids[1]);
      }

      if (this.modifiedResults[result.id()]) {
        result = this.modifiedResults[result.id()];
      }

      var repair = new Repair(this.domain);
      repair
        .withOption(option)
        .withCreatedDateNow()
        .done();

        if (option.isRemark()) {
        if (option.isMajor()) {
          repair.withRemarks(answer.remarksMajor());
        } else {
          repair.withRemarks(answer.remarksMinor());
        }
      }

      var answerRepairs = this.getRepairs(answer);
      answerRepairs.add(repair);
      answer.withRepairs(answerRepairs);

      var answers = this.getAnswers(result);
      answers.add(answer);
      result.withAnswers(answers);

      if (answers.hasUnfixedMajors()) {
        result.withUnfixedDefectTypeMajor();
      } else {
        if (answers.hasUnfixedMinors()) {
          result.withUnfixedDefectTypeMinor();
        } else {
          result.withUnfixedDefectTypeNone();
        }
      }

      this.modifiedRepairs.add(repair);
      this.modifiedAnswers.add(answer);
      this.modifiedResults.add(result);

      this.mainScreen();
    },

    getAnswers(result) {
      if (this.resultAnswers[result.id()]) {
        return this.resultAnswers[result.id()];
      }
      var answers = result.answers().find();
      this.resultAnswers[result.id()] = answers;
      return answers;
    },
    
    getRepairs(answer) {
      var id = answer.id();
      if (this.answerRepairs[id]) {
        return this.answerRepairs[id];
      }
      var repairs = answer.repairs().find();
      this.answerRepairs[id] = repairs;
      return repairs;
    },

    doSendAction() {
        this.resetMissingValues();
        if (!this.areRequirementsMet()) {
            this.showMissingRequirements = true;
            this.mainScreen();
            return;
        }

        this.sendingScreen();
        var context = {
            view: this
        };

        var repairResults = this.inspection.repairResults();

        var repairResult = new RepairResult(this.domain)
        repairResult
          .withEmployeeCurrent()
          .withWorkOrder(this.workOrder)
          .withRepairDateNow()
          .withSignatureCurrentUser()
          .withRepairs(this.modifiedRepairs)
          .done();
        
        repairResults.add(repairResult);
        var inspection = this.inspection.copy();
        inspection.withRepairResults(repairResults);
        inspection.addResults(this.modifiedResults);
        inspection.withBeenRepaired();

        var session = this.domain.session();
        var employee = session.employee();
        var user = session.user();

        if (!StringUtils.isEmpty(this.remark)) {
          var comment = new Comment(this.domain);
          comment
            .withText(this.remark)
            .with(employee)
            .withCreatedDateNow()
            .withCreatedBy(user)
          var comments = inspection.comments();
          comments.add(comment);
          inspection.with(comments);
        }

        var results = this.allResults;
        results.addResults(this.modifiedResults);

        var type = results.mostSevereUnfixedDefectType();
        inspection
          .withDefectType(type)
          .withActionRepair()

        var event = this.domain.events().inspections().save(inspection);
        event.send(this.savedListener, context);
    },

    savedListener(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      if (notification.error()) {
        this.error = notification.error();
        this.mainScreen();
        return;
      }

      var buttonData = {};
      buttonData["field"] = "Close";

      this.$emit("withButtonPressed", buttonData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet() {
      var met = true;

      if(!this.isSigned) {
        met = false;
      }

      if(this.repairRequired) {
        met = false;
      }

      return met;
    },
  },
}

</script>

