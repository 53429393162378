import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import MC from "@/domain/session/MC.js";
import InspectionRow from "@/portals/shared/cell/inspection/InspectionRow";
import ContentUtils from '@/utils/ContentUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  static SORT_FIELDS() {
    return [
      { '@rid': 'createdDateAz', 'name': 'Created Date (Old -> New)' },
      { '@rid': 'createdDateZa', 'name': 'Created Date (New -> Old)' },
    ];
  }
  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().inspections();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new InspectionRow().withInspection(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }


  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "inspection")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let user = this.model().user();
    let userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    let vehicle = this.model().vehicle();
    let vehicleId = vehicle.id();
    if (vehicle.isNew()) {
      vehicleId = "";
    }
    if (vehicleId) {
      //
    }
    let filterBox = new BoxDisplayRow()
    let itemFilterRow = new RowDisplayRow()
    .withChild(new BoxDisplayRow().withWidth("7")
      .withChild(new StringListUpdateRow().withWidth("6").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
    )
    .withChild(new BoxDisplayRow().withWidth("8")
      .withChild(new StringListUpdateRow().withWidth("6").withName(this.inspectableTitle()).withKey("pickVehicle").withField("Vehicle").withDefaultValue(vehicleId).withOptions(this.vehicleList()).withShowClear(true))
    );

    if (query.employeeListAction) {
      itemFilterRow = new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("7")
        .withChild(new SpacerDisplayRow())
        .withChild(new LabelValueDisplayRow().withWidth(6).withLabel("User").withValue(user.fullName() + " (" + user.email() +")")
      ))
      .withChild(new BoxDisplayRow().withWidth("8")
        .withChild(new StringListUpdateRow().withWidth("6").withName(this.inspectableTitle()).withKey("pickVehicle").withField("Vehicle").withDefaultValue(vehicleId).withOptions(this.vehicleList()).withShowClear(true))
      );
    }

    if (query.vehicleListAction) {
      itemFilterRow = new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("7")
        .withChild(new StringListUpdateRow().withWidth("6").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
      )
      .withChild(new BoxDisplayRow().withWidth("8")
        .withChild(new SpacerDisplayRow())
          .withChild(new LabelValueDisplayRow().withLabelWidth(5).withWidth(6).withLabel(this.inspectableTitle()).withValue(vehicle.displayName())));
    }
    
    if (query.equipmentListAction) {
      itemFilterRow = new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("7")
        .withChild(new StringListUpdateRow().withWidth("6").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
      )
      .withChild(new BoxDisplayRow().withWidth("8")
        .withChild(new SpacerDisplayRow())
        .withChild(new LabelValueDisplayRow().withLabelWidth(5).withWidth(6).withLabel(this.inspectableTitle()).withValue(vehicle.name())));
    }

    let timeFilter = this.model().pageFilter(query, "Filter.Time", "last30Days", "inspection");
    let statusFilter = this.model().pageFilter(query, "Filter.Status", "all", "inspection");
    filterBox
      .withWidth("15")
      .withBorder(true)
      .withChild(new LabelDisplayRow().withLabel("Filters"))
      .withChild(new RowDisplayRow()
        .withChild(new LabelDisplayRow().withWidth("1").withLabel("Status"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStatus").withField("Filter.Status").withDefaultValue("all").withSelected(statusFilter === "all"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Good").withKey("goodStatus").withField("Filter.Status").withDefaultValue("good").withSelected(statusFilter === "good"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Minors").withKey("minorStatus").withField("Filter.Status").withDefaultValue("minor").withSelected(statusFilter === "minor"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Majors").withKey("majorStatus").withField("Filter.Status").withDefaultValue("major").withSelected(statusFilter === "major"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Any").withKey("anyStatus").withField("Filter.Status").withDefaultValue("any").withSelected(statusFilter === "any"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Repaired").withKey("repairedStatus").withField("Filter.Status").withDefaultValue("repaired").withSelected(statusFilter === "repaired"))
      )
      .withChild(new RowDisplayRow()
        .withChild(new LabelDisplayRow().withShowLabel(true).withWidth("1").withLabel("Time"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("All").withKey("allTime").withField("Filter.Time").withDefaultValue("all").withSelected(timeFilter === "all"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("Today").withKey("todayTime").withField("Filter.Time").withDefaultValue("today").withSelected(timeFilter === "today"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("1").withName("Yesterday").withKey("yesterdayTime").withField("Filter.Time").withDefaultValue("yesterday").withSelected(timeFilter === "yesterday"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 2 Weeks").withKey("last2WeeksTime").withField("Filter.Time").withDefaultValue("last2Weeks").withSelected(timeFilter === "last2Weeks"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("This Month").withKey("thisMonthTime").withField("Filter.Time").withDefaultValue("thisMonth").withSelected(timeFilter === "thisMonth"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last Month").withKey("thisMonthTime").withField("Filter.Time").withDefaultValue("lastMonth").withSelected(timeFilter === "lastMonth"))
        .withChild(new ButtonDisplayRow().withShowLabel(true).withWidth("2").withName("Last 30 days").withKey("last30DaysTime").withField("Filter.Time").withDefaultValue("last30Days").withSelected(timeFilter === "last30Days"))
        .withChild(new DateUpdateRow().withWidth("3").withName("Show Inspectins Starting At").withKey("pickTime").withField("Filter.Time").withDefaultValue(isNaN(timeFilter) ? "" : timeFilter ))
      );
    if (!this.model().isAdmin()) {
      filterBox
        .withChild(itemFilterRow);
    } else {
      let user = this.domain().users().findById(userId);
      let vehicle = this.domain().vehicles().findById(vehicleId);
      itemFilterRow = new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth("7")
          .withChild(new LabelValueDisplayRow().withWidth(6).withLabel("User").withField("User").withValue(this.valueOrNotSet(user.fullName() + " " + user.email())))
        )
        .withChild(new BoxDisplayRow().withWidth("8")
          .withChild(new LabelValueDisplayRow().withWidth(6).withLabel("Vehicle").withField("Vehicle").withValue(this.valueOrNotSet(vehicle.plate() + " " + vehicle.name())))
        );
      filterBox
        .withChild(itemFilterRow);
    }

    let sortByValue = this.model().pageSortBy(query, "createdDateZa", "inspection");
    let sortByUpdateRow = new StringListUpdateRow()
                                  .withWidth(4)
                                  .withName("Sort By")
                                  .withField("SortBy")
                                  .withShowLabel(false)
                                  .withDefaultValue(sortByValue)
                                  .withOptions(ListViewUtils.SORT_FIELDS());
    let sortByRow = new LabelDisplayRow().withWidth(1).withLabel("Sort By");
    
    // sortByRow = new SpacerDisplayRow().withWidth(4);

    if (totalItems == null || totalItems == undefined) {
      totalItems = 100;
    }
    this.addHeader(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelDisplayRow().withWidth("10").withLabel("Inspections"))
          .withChild(new SpacerDisplayRow().withWidth("4"))
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(filterBox)
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "inspection")))
              .withChild(sortByRow)
              .withChild(sortByUpdateRow)
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query, "inspection"))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "inspection"))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    let box = new BoxDisplayRow();
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new RowDisplayRow().withWidth("10").withChildren([
          new TitleDisplayRow().withWidth("8").withTitle("Details"),
          new TitleDisplayRow().withWidth("7").withTitle("Inspected Item")
        ]))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Trailers"))
      );

    this.addHeader(box);
  }

  inspectableTitle() {
    if (this.model().company().hasEquipment()) {
      return "Vehicle, Trailer or Equipment";
    }
    return "Vehicle or Trailer";
  }

}