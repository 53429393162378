
import RcObject from "@/domain/common/RcObject.js";
import Inspection from "@/domain/model/inspection/Inspection.js";
import Company from "@/domain/model/company/Company.js";

export default class VehicleListFilter extends RcObject {
  
  static Fields = {
    DEFECT_TYPE: Inspection.Fields.DEFECT_TYPE,
    PAGINATION: 'pagination',
    STATE_TYPE: 'state',
    VEHICLE_TYPE: 'type',
    USAGE_TYPE: 'usageType',
    SAFETY_DATE_TYPE: 'safetyDate',
    COMPANY: Company.MODEL_NAME,
    SEARCH_TEXT: 'searchText',
    IDS: 'ids',
  }
  
  constructor() {
    super({});
  }
  
  withSearchText(text) {
    this.put(VehicleListFilter.Fields.SEARCH_TEXT, { 'text': text });
    return this;
  }
  
  withCompany(companyId) {
    this.put(VehicleListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withState(state) {
    this.put(VehicleListFilter.Fields.STATE_TYPE, state);
    return this;
  }
  
  withType(type) {
    this.put(VehicleListFilter.Fields.VEHICLE_TYPE, type);
    return this;
  }
  
  withDefectType(type) {
    this.put(VehicleListFilter.Fields.DEFECT_TYPE, type);
    return this;
  }
  
  withPagination(size, number) {
    this.put(VehicleListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withIds(ids) {
    this.put(VehicleListFilter.Fields.IDS, ids);
    return this;
  }

  withUsageType(usageType) {
    this.put(VehicleListFilter.Fields.USAGE_TYPE, usageType);
    return this;
  }

  withSafetyDate(safetyDate) {
    this.put(VehicleListFilter.Fields.SAFETY_DATE_TYPE, safetyDate);
    return this;
  }
  
  done() {
    //
  }
}