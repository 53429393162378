<template>
    <RepairView 
      :itemId="itemId" 
      :totalItems="totalItems"
      @click="withButtonPressed" 
      @withButtonPressed="withButtonPressed" 
      @withValue="withValue"></RepairView>
  </template>
  
  <script>
  import RepairView from './RepairView.vue';
  export default {
    name: "inspection-list-repair-row",
    components: {
      RepairView
    },
    props: {
      itemId: { type: String, default: "" },
      selectedInspectionIds: { default: [] },
      totalItems: { type: Number, default: 0 },
    },
    methods: {
      withValue: function(event) {
        this.$emit('withValue', event);
      },
      withButtonPressed: function(event) {
        this.$emit('withButtonPressed', event);
      },
    }
  }
  </script>
  